<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-dialog v-model="display" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-badge :value="numFilters" bordered overlap color="tips" :content="numFilters">
          <v-btn small color="primary" v-on="on"><v-icon small left>mdi-filter</v-icon>Filter</v-btn>

        </v-badge>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Oncall Preshift Preps</span>
        </v-card-title>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <project-combobox v-model="local_project" label="Projects" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <individual-combobox v-model="local_handover_to" label="Handover To" />
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list-item>
          <v-list-item-content>
            <v-checkbox v-model="local_handshake_completed" label="Handshake Completed" />
          </v-list-item-content>
        </v-list-item>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="applyFilters()"> Apply Filters </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>

<script>
import { sum, cloneDeep } from "lodash"
import { mapFields } from "vuex-map-fields"


import ProjectCombobox from "@/project/ProjectCombobox.vue"
import IndividualCombobox from '@/individual/IndividualCombobox.vue'

export default {
  name: "OncallPreshiftPrepTableFilterDialog",

  components: {
    ProjectCombobox,
    IndividualCombobox,
  },

  props: {
    projects: {
      type: Array,
      default: function () {
        return []
      },
    },
  },

  data() {
    return {
      display: false,
      local_project: this.projects,
      local_handover_to: [],
      local_handshake_completed: false,
    }
  },

  created() {
    this.local_project = cloneDeep(this.project)
    this.local_handover_to = cloneDeep(this.individual_contact)
    this.local_handshake_completed = cloneDeep(this.handshake_completed)
  },

  computed: {
    ...mapFields("oncall_preshift_prep", [
      "table.options.filters.project",
      "table.options.filters.individual_contact",
      "table.options.filters.handshake_completed"
    ]),

    numFilters: function () {
      return sum([
        this.project.length,
        this.individual_contact.length,
        this.handshake_completed ? 1 : 0,
      ])
    },
  },

  methods: {
    applyFilters() {
      // we set the filter values
      this.project = this.local_project
      this.individual_contact = this.local_handover_to
      this.handshake_completed = this.local_handshake_completed

      // we close the dialog
      this.display = false
    },
  },
}
</script>
