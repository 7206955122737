<template>
    <ValidationObserver v-slot="{ invalid, validated }">
      <v-navigation-drawer
        v-model="showNewSheet"
        app
        clipped
        right
        width="800"
        :permanent="$vuetify.breakpoint.mdAndDown"
        >
        <template v-slot:prepend>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title v-if="id" class="title"> Edit </v-list-item-title>
              <v-list-item-title v-else class="title"> New </v-list-item-title>
              <v-list-item-subtitle>Oncall Preshift Prep</v-list-item-subtitle>
            </v-list-item-content>
            <v-btn
              icon
              color="info"
              :loading="loading"
              :disabled="invalid || !validated"
              @click="save()"
            >
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn icon color="secondary" @click="closeNewSheet">
            <v-icon>close</v-icon>
          </v-btn>
          </v-list-item>
        </template>
        <v-card flat>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <ValidationProvider name="Description" rules="required" immediate>
                                    <individual-select
                                    v-model="handover_to"
                                    label="Handover To"
                                    slot-scope="{ errors, valid }"
                                    :error-messages="errors"
                                    :success="valid"
                                    hint="The organization member who is receiving the preshift prep"
                                    clearable
                                    :project="project"
                                    immediate
                                    />
                                </ValidationProvider>
                            </v-flex>
                            <v-flex xs12>
                                <ValidationProvider name="Description" rules="required" immediate>
                                    <project-select
                                    v-model="project"
                                    immediate
                                />
                                </ValidationProvider>
                            </v-flex>
                            <v-flex xs12>
                                <v-checkbox
                                    v-model="sent"
                                    label="Sent"
                                    color="primary"
                                ></v-checkbox>
                            </v-flex>
                            <v-flex xs12>
                                <date-time-picker-menu
                                    v-model="sent_at"
                                    label="Sent Time"
                                ></date-time-picker-menu>
                            </v-flex>
                            <v-flex xs12>
                                <v-checkbox
                                    v-model="accepted"
                                    label="Accepted"
                                    color="primary"
                                ></v-checkbox>
                            </v-flex>
                            <v-flex xs12>
                                <date-time-picker-menu
                                    v-model="accepted_at"
                                    label="Accepted Time"
                                ></date-time-picker-menu>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                    v-model="shift_type"
                                    label="Shift Type"

                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                    v-model="location_type"
                                    label="Location Type"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-checkbox
                                    v-model="handshake_completed"
                                    label="Handshake Completed"
                                    color="primary"
                                ></v-checkbox>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-navigation-drawer>
    </ValidationObserver>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"
import { ValidationObserver, extend, ValidationProvider } from "vee-validate"
import { required } from "vee-validate/dist/rules"
import IndividualSelect from "@/individual/IndividualSelect.vue"
import ProjectSelect from "@/project/ProjectSelect.vue"
import DateTimePickerMenu from "@/components/DateTimePickerMenu.vue"


extend("required", {
    ...required,
    message: "This field is required",
})

export default {
    name: "OncallPreshiftPrepNewSheet",


components: {
    ValidationObserver,
    ValidationProvider,
    IndividualSelect,
    ProjectSelect,
    DateTimePickerMenu,
},
computed: {
    ...mapFields("oncall_preshift_prep", [
        "selected.handover_to",
        "selected.shift_type",
        "selected.location_type",
        "selected.accepted",
        "selected.accepted_at",
        "selected.handshake_completed",
        "selected.sent",
        "selected.sent_at",
        "dialogs.showCreateEdit",
        "selected.project",
        "selected.id",
        "selected.loading",
        "dialogs.showNewSheet",
    ]),
    ...mapFields("route", ["query"]),
},
data() {
    return {}
},

methods: {
    fetchDetails() {
        this.getDetails({ id: this.$route.params.id })
    },
    ...mapActions("oncall_preshift_prep", ["save", "getDetails", "closeNewSheet"]),
},
watch: {
    "$route.params.id": function () {
        this.fetchDetails()
    },
},
created() {
    if (this.query.project) {
        this.project = { name: this.query.project }
    }
    this.fetchDetails()
},
}
</script>
