<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-container fluid>
      <div v-if="showEditSheet">
        <router-view />
      </div>
      <delete-dialog />
      <v-row no-gutters>
        <v-col>
          <div class="headline">
            <v-icon large>mdi-creation</v-icon>
            Business Activity Preps
          </div>
        </v-col>
        <v-col class="text-right">
          <table-filter-dialog :projects="defaultUserProjects" />
          <v-btn small color="info" class="ml-2" @click="showNewSheet()">
            <v-icon small>mdi-plus</v-icon>
            New IAO Prep
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <new-sheet />
        <delete-dialog />
        <v-col>
          <v-card elevation="0">
            <v-card-title></v-card-title>
              <!-- Search Bar -->
            <v-text-field
              class="mb-1"
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
            <v-row>
              <v-expansion-panels popout>
              <v-col cols="12" v-for="item in items" :key="item.id">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                  <div class="d-flex flex-row justify-space-between align-center">
                    <div class="d-flex flex-column flex-grow-1">
                      <div v-if="item.handover_to">
                        To: <individual :individual="item.handover_to" />
                      </div>
                      <div v-else>
                        To: Unknown
                      </div>
                      <v-chip small :color="item.handshake_completed ? 'success' : 'warning'" class="mt-2">
                        <v-icon small left>
                          {{ item.handshake_completed ? 'mdi-check' : 'mdi-clock-alert-outline' }}
                        </v-icon>
                        {{ item.handshake_completed ? 'Completed' : 'Ongoing' }}
                      </v-chip>
                    </div>
                    <div class="d-flex flex-column flex-grow-1">
                      <v-chip small class="ml-2">
                        <v-icon small color="success" left class="mr-1">mdi-clock-time-four-outline</v-icon>
                        Sent:
                        {{ item.sent_at | formatRelativeDate }}
                      </v-chip>
                    </div>
                    <div class="d-flex flex-column flex-grow-1">
                      <v-chip small class="ml-2" v-if="item.accepted_at">
                        <v-icon small color="green accent-4" left class="mr-1">mdi-clock-check-outline</v-icon>
                        Accepted:
                        {{ item.accepted_at | formatRelativeDate }}
                      </v-chip>
                      <v-chip small class="ml-2" v-else>
                        <v-icon small color="warning" left class="mr-1">mdi-clock-alert-outline</v-icon>
                        Awaiting Acceptance
                      </v-chip>
                    </div>
                  </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-card outlined class="ma-3">
                    <v-card-title class="d-flex justify-space-between align-center">
                      <span>Preshift Preparation</span>
                      <v-chip :color="item.handshake_completed ? 'success' : 'warning'">
                        <v-icon left>
                          {{ item.handshake_completed ? 'mdi-check' : 'mdi-progress-clock' }}
                        </v-icon>
                        {{ item.handshake_completed ? 'Completed' : 'Ongoing' }}
                      </v-chip>
                    </v-card-title>
                    <v-card-subtitle>
                      <div v-if="item.handover_to">
                        To: <individual :individual="item.handover_to" />
                      </div>
                      <div v-else>
                        To: Unknown
                      </div>
                      <div>
                        Project: <project-card :project="item.project" />
                      </div>
                    </v-card-subtitle>
                    <v-card-text>
                      Sent Time:<br />
                      <v-chip>
                        <v-icon color="cyan darken-1" left class="mr-1">mdi-clock-time-four-outline</v-icon>
                        {{ item.sent_at | formatRelativeDate }}
                      </v-chip>
                      <div v-if="item.accepted_at">
                        Accepted Time: <br>
                        <v-chip dark small color="green accent-4">
                          <v-icon small left class="mr-1">mdi-clock-check-outline</v-icon>
                          {{ item.accepted_at | formatRelativeDate }}
                        </v-chip>
                      </div>
                      <div v-else>
                        Acceptance:<br />
                        <v-chip small>
                          <v-icon color="warning" small left class="mr-1">mdi-clock-alert-outline</v-icon>
                          Pending
                        </v-chip>
                      </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-menu bottom left>
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on">
                            <v-icon>
                              mdi-dots-vertical
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item :to="{ name: 'OncallPreshiftPrepTableEdit', params: { id: item.id } }">
                            <v-list-item-title>View / Edit</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="removeShow(item)">
                            <v-list-item-title>Delete</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-card-actions>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
              </v-col>
            </v-expansion-panels>
            </v-row>
            <v-data-table
            :items="items"
            :server-items-length="total"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :footer-props="{
              'items-per-page-options': [10, 25, 50, 100],
            }"
            :sort-by.sync="sortBy"
            :sort-desc.sync="descending"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
          </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>


<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"
import RouterUtils from "@/router/utils"

import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
import DeleteDialog from "@/oncall/preshift_prep/DeleteDialog.vue"
import NewEditSheet from "@/oncall/preshift_prep/NewEditSheet.vue"
import NewSheet from "@/oncall/preshift_prep/NewSheet.vue"
import Individual from "@/individual/Individual.vue"
import TableFilterDialog from "@/oncall/preshift_prep/TableFilterDialog.vue"
import ProjectCard from '@/project/ProjectCard.vue'


export default {
  name: "OnCallPreShiftPrepTable",
  components: {
    DeleteDialog,
    NewEditSheet,
    SettingsBreadcrumbs,
    Individual,
    TableFilterDialog,
    NewSheet,
    ProjectCard,
  },
  data() {
    return {
      showEditSheet: false,
    }
  },
  computed: {
    ...mapFields("oncall_preshift_prep", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters",
      "table.options.filters.project",
      "table.options.filters.individual_contact",
      "table.options.filters.handshake_completed",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),
    ...mapFields("auth", ["currentUser.projects"]),

    defaultUserProjects: {
      get() {
        let d = null
        if (this.projects) {
          let d = this.projects.filter((v) => v.default === true)
          return d.map((v) => v.project)
        }
        return d
      },
    },
  },
  created() {
    this.filters = {
      ...this.filters,
      ...RouterUtils.deserializeFilters(this.query),
      project: this.defaultUserProjects,
    }

    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project, vm.individual_contact, vm.handshake_completed],
      () => {
        this.page = 1
        RouterUtils.updateURLFilters(this.filters)
        this.getAll()
      }

    )
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (newVal) {
        this.showEditSheet = newVal.meta && newVal.meta.showEditSheet
      },
    },
  },
  methods: {
    ...mapActions("oncall_preshift_prep", ["getAll", "createEditShow", "removeShow", "showNewSheet"]),
    showOncallPreshiftPrepEditSheet(item) {
      this.$router.push({ name: "OncallPreshiftPrepTableEdit", params: { id: item.id } })
    },
  },
}
</script>
